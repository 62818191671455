<template>
  <div class="section4" id="section4">
    <div>
      <h1>Biaya Keuangan</h1>
      <p>Informasi biaya keuangan Peserta Didik Baru untuk SMP Al Bayan Islamic School tahun ajaran 2023-2024</p>
    </div>
    <div>
      <table class="custom-table">
        <thead>
          <tr>
            <th>No</th>
            <th>Jenis Keuangan</th>
            <th>Biaya</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for=" item  in  dataTable " :key="item.key">
            <td style="text-align: center;">{{ item.no }}</td>
            <td>{{ item.jenisKeuangan }}</td>
            <td style="text-align: right;">{{ item.biaya }}</td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: center;color: #357C2C;" class="totalBiaya">
              <strong>JUMLAH</strong>
            </td>
            <td style="text-align: right;color: #357C2C;" class="totalBiaya"><strong>Rp. 11.500.000,-</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      someList: [
        {
          html: 'slider1',
          style: {
            background: '#4abf8a',
            width: '700px',
            cursor: 'pointer',
          },
          path: require('@/assets/ip1.webp'),
        },
        {
          html: 'slider2',
          style: {
            background: '#4bbfc3',
            width: '700px',
            cursor: 'pointer',
          },
          path: require('@/assets/ip2.webp'),
        },
        {
          html: 'slider3',
          style: {
            background: '#7baabe',
            width: '700px',
            cursor: 'pointer',
          },
          path: require('@/assets/ip2.webp'),
        },
      ],
      options: {
        currentPage: 0,
        speed: 300,
        itemAnimation: true,
        centeredSlides: true,
        thresholdDistance: 100,
        thresholdTime: 300,
        loopedSlides: 2,
        slidesToScroll: 1,
        loop: true,
      },
      dataTable: [
        {
          key: '1',
          no: '1',
          jenisKeuangan: 'Uang Pembangunan',
          biaya: 'Rp. 5.000.000',
        },
        {
          key: '2',
          no: '2',
          jenisKeuangan: 'Pengembangan sarana dan prasarana',
          biaya: 'Rp. 2.000.000',
        },
        {
          key: '3',
          no: '3',
          jenisKeuangan: 'Kegiatan akademik',
          biaya: 'Rp. 1.000.000',
        },
        {
          key: '4',
          no: '4',
          jenisKeuangan: 'SPP Bulan Juli 2023',
          biaya: 'Rp. 750.000',
        },
        {
          key: '5',
          no: '5',
          jenisKeuangan: 'Kegiatan Kesiswaan Selama Setahun',
          biaya: 'Rp. 1.500.000',
        },
        {
          key: '6',
          no: '6',
          jenisKeuangan: 'Seragam Sekolah (1 Stel Yayasan, 1 Stel Batik, 1 Stel Olahraga, Jas Almamater, Badge dan Topi)',
          biaya: 'Rp. 1.200.000',
        },
        {
          key: '7',
          no: '7',
          jenisKeuangan: 'Sarana Kebutuhan Siswa (Asuransi, Buku Kendali Siswa, Kartu Pelajar, Map Raport, Map Ijazah)',
          biaya: 'Rp. 500.000',
        },
      ],
      visible: false,
      modalImage: 0,
    }
  },
  computed: {
    // ...mapState(['currentRoute']), // Anda dapat menggunakan Vuex untuk mengelola route aktif
    institusi() {
      return this.$store.state.master.institusi
    },
  },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    onChange(a, b, c) {
      console.log(a, b, c)
    },
    showModal(idx) {
      this.visible = true
      this.modalImage = idx
    },
    handleOk(e) {
      console.log(e)
      this.visible = false
    },
  },
}
</script>
<style scoped>
.section4 {
  margin-top: 70px;
  /* background-color: aqua; */
  /* padding-top: 50px; */
  padding: 50px;
  /* padding-left: 145px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.section4 h1 {
  color: #357C2C;
  font-family: Raleway;
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  text-align: center;
  /* 52.5px */
}

.section4 p {
  padding-top: 20px;
  text-align: center;
  /* width: 600px; */
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  /* 48px */
}

.custom-table {
  /* background-color: #357C2C; */
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  /* Menggabungkan garis tepi sel */
  /* width: 1200px; */
  height: auto;
  /* Lebar tabel */
  margin-top: 50px;
  /* Jarak atas tabel dari elemen sebelumnya */
}

/* Styling untuk header kolom */
.custom-table th {
  background-color: #E6F4F1;
  /* Warna latar belakang header */
  /* Garis tepi header */
  padding: 16px 16px 17px 16px;
  align-items: center;
  gap: 16px;
  /* Ruang dalam header */
  color: #00373E;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 27px */
  letter-spacing: 0.3px;
}

/* Styling untuk sel data */
.custom-table td {
  border-bottom: 1px solid #F0F0F0;
  /* Garis tepi sel data */
  padding: 16.5px 16px 17.5px 16px;
  text-align: left;
  /* Ruang dalam sel data */
  color: #00373E;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
  letter-spacing: 0.3px;
}

.totalBiaya {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 27px */
  letter-spacing: 0.3px;
}

@media screen and (max-width: 560px) {
  .section4 h1 {
    font-size: 30px;
  }

  .section4 p {
    padding-top: 10px;
  }

  .custom-table th {
    font-size: 12px;
  }

  .custom-table td {
    font-size: 12px;
  }

  .totalBiaya {
    font-size: 12px;
  }
}

@media screen and (max-width: 1400px) {}
</style>
